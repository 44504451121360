import { defineStore } from "pinia";
import { useQuery } from "@vue/apollo-composable";

export const useAuthStore = defineStore("AuthStore", {
  state: () => ({
    currentUser: null,
    logo: "",
  }),

  getters: {
    authCheck: (state) => (state.currentUser ? true : false),
  },

  actions: {
    async getUser() {
      try {
        console.log("Fetching User 🙋‍ ");
        const { onResult } = await useQuery(whoAmI);

        onResult((queryResult) => {
          const primary_color =
            queryResult.data.me.client.Details.primary_color &&
            queryResult.data.me.client.Details.primary_color.length !== 0
              ? queryResult.data.me.client.Details.primary_color
              : "#ff6600";

          const secondary_color =
            queryResult.data.me.client.Details.secondary_color &&
            queryResult.data.me.client.Details.secondary_color.length !== 0
              ? queryResult.data.me.client.Details.secondary_color
              : "#ff6600";

          const rounded_md =
            queryResult.data.me.client.Details.is_rounded === false
              ? "0rem"
              : "0.375rem";

          const rounded_xl =
            queryResult.data.me.client.Details.is_rounded === false
              ? "0rem"
              : "0.5rem";

          document.documentElement.style.setProperty(
            "--primary",
            primary_color
          );
          document.documentElement.style.setProperty(
            "--secondary",
            secondary_color
          );

          document.documentElement.style.setProperty("--md-round", rounded_md);
          document.documentElement.style.setProperty("--lg-round", rounded_xl);
          document.documentElement.style.setProperty("--xl-round", rounded_xl);

          this.logo = queryResult.data.me.client.Details.primary_logo;
          this.currentUser = queryResult.data.me;

          if (queryResult.data.me.role.title === "ADMIN") {
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
});

const whoAmI = gql`
  query me {
    me {
      id
      name
      firstname
      email
      salutation
      role {
        title
      }
      client {
        Details {
          primary_color
          secondary_color
          primary_logo
          is_rounded
          kickback
          provision
        }
      }
    }
  }
`;
